
import { defineComponent } from "vue";
import PlanTransportGroupResultLoadingSchemeJournal from "@/components/journals/PlanTransportGroupResultJournal/PlanTransportGroupResultLoadingSchemeJournal.vue";
import { TableEditingConfig } from "@/components/controls/catalogueGrid/tableEditingConfig";
import { DxLoadPanel } from "devextreme-vue/load-panel";
import { getPlanTransportGroupResultLoadingScheme } from "@/api/planTransportGroupResultLoadingSchemeApi";
import store from "@/store";

export default defineComponent({
  components: {
    PlanTransportGroupResultLoadingSchemeJournal,
    DxLoadPanel,
  },
  props: {
    parentModel: { required: true },
    parentEditingConfig: { required: false, type: TableEditingConfig },
  },
  computed: {
    cargoes() {
      if (this.model.cargoes) {
        let groups = this.model.cargoes;
        let result = ``;
        groups.forEach((element: any) => {
          result += ` ${element.name} - ${element.count} шт. <br>`;
        });
        return result;
      }
      return "";
    },
    loadingSchemeDomId() {
      return `loadingSchemeData_${this.parentModel.id}`;
    },
  },
  methods: {
    onContentReady(e: any) {
      if (!this.firstRowSelected && e.component.totalCount() > 0) {
        e.component.selectRowsByIndexes([0]);
        this.firstRowSelected = true;
      }
    },
    async onSelectionChanged(e: any) {
      if (e.selectedRowsData && e.selectedRowsData.length) {
        const rowData = e.selectedRowsData[0];
        try {
          this.loading = true;
          const { data } = await getPlanTransportGroupResultLoadingScheme(
            1589,
            rowData.transportType,
            rowData.planTransportResultLoadingSchemeId,
            this.parentModel.id
          );
          this.model = data;
          this.model.number = rowData.number;
          this.$forceUpdate();
        } finally {
          this.loading = false;
        }
      }
    },
  },
  async mounted() {
    this.firstRowSelected = false;
    this.loadingPanelPosition = { of: `#${this.loadingSchemeDomId}` };
  },
  data() {
    return {
      model: {},
      firstRowSelected: false,
      loading: false,
      loadingPanelPosition: {},
      showSchemeFromVehicleBack: false,
      showSchemeCuts: false,
      showSchemeLevels: false,
    };
  },
  created() {
    const settings = (store as any).state.settings.settings;

    if (settings) {
      this.showSchemeFromVehicleBack = settings.showSchemeFromVehicleBack;
      this.showSchemeCuts = settings.showSchemeCuts;
      this.showSchemeLevels = settings.showSchemeLevels;
    }
  },
});
